import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import isEmail from 'validator/lib/isEmail';

import HeroTwo from '../components/HeroTwo';
import logo from '../images/logo.svg';
import favicon from '../images/favicon.svg';

const useStyles = makeStyles((theme) => ({
  header: {
    ...theme.typography.header,
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  paper: {
    boxSizing: 'border-box',
    width: '60%',
    margin: 'auto',
    padding: '2rem',
    marginBlock: '1rem',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      marginBlock: '1rem',
      width: '100%',
    },
  },
  fieldContainer: {
    marginBottom: '.5rem',
  },
  nameField: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: '.5rem',
    },
  },
  sendButton: {
    marginTop: '1.5rem',
    padding: '0.5rem 1.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem 1rem',
    },
  },
  container: {
    paddingBlockEnd: '12rem',
    [theme.breakpoints.down('md')]: {
      paddingBlockEnd: '8rem',
    },
  },
}));

const ContactPage = () => {
  const classes = useStyles();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [emailHelper, setEmailHelper] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
    }
  };
  const handleEmailChange = (e) => {
    let valid;
    setEmail(e.target.value);
    valid = isEmail(e.target.value) || e.target.value === '';
    !valid ? setEmailHelper('Invalid email') : setEmailHelper('');
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    console.log(`${process.env.GATSBY_GETFORM_API_KEY}`);
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: 'post',
      url: `${process.env.GATSBY_GETFORM_API_KEY}`,
      data: { firstName, lastName, email, message },
    })
      .then((r) => {
        handleServerResponse(true, 'Message Sent!', form);
        setFirstName('');
        setLastName('');
        setEmail('');
        setMessage('');
        setOpenSnackbar(true);
      })
      .catch((r) => {
        handleServerResponse(false, 'Unable to send', form);
        setOpenSnackbar(true);
      });
  };
  return (
    <div className={classes.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href={favicon} />
        <meta property="og:title" content="Nabhas Alakkat | Portfolio" />
        <meta property="og:description" content="my web developer portfolio" />
        <meta property="og:image" content={logo} />
        <meta property="og:url" content="https://alakkat.com/contact" />
        <title>Nabhas Alakkat | Contact</title>
        <link rel="canonical" href="https://alakkat.com/contact" />
      </Helmet>
      <HeroTwo title="Contact Me" />
      <Paper className={classes.paper}>
        <form onSubmit={handleOnSubmit}>
          <Grid container className={classes.fieldContainer}>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                id="firstName"
                variant="outlined"
                label="First Name"
                type="text"
                className={classes.nameField}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
                required
                id="lastName"
                variant="outlined"
                label="Last Name"
                type="text"
              />
            </Grid>
          </Grid>
          <TextField
            value={email}
            onChange={handleEmailChange}
            error={emailHelper.length !== 0}
            helperText={emailHelper}
            fullWidth
            required
            id="email"
            variant="outlined"
            label="Email"
            type="email"
            className={classes.fieldContainer}
          />
          <TextField
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            fullWidth
            required
            id="message"
            variant="outlined"
            multiline
            rows={4}
            label="Message"
            className={classes.fieldContainer}
          />
          <Grid container justify="center">
            <Grid item>
              <Button
                disableRipple
                disabled={
                  firstName.length === 0 ||
                  lastName.length === 0 ||
                  email.length === 0 ||
                  message.length === 0 ||
                  emailHelper.length !== 0
                }
                variant="contained"
                color="primary"
                type="submit"
                className={classes.sendButton}
                endIcon={<SendIcon />}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        message={serverState.status ? serverState.status.msg : ''}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
              disableRipple
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default ContactPage;
